<template>
    <div class="mx-lg-0 mx-auto">
        <router-link v-if="homeData" to="/" class="logo-img d-inline-block">
          <img loading="lazy"
          src="@/assets/images/logo.png"

           class="w-100 h-100" alt="neekshop" />
        </router-link>
      </div>
</template>
<script>
export default{
  computed:{
    homeData() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null
      } else{
        this.$store.getters["front/getHomeData"]
      }
    }
  }
}
</script>
