<template>
  <div>
    <div
      class="container bg-light boxShadow20 p-5 mt-3"
      style="border-radius: 20px;"
    >
      <div>
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12 column">
            <div>
              <h4>
                <strong>جذب نمایندگی پوشاک شاهین کالا</strong>
              </h4>

              <p></p>

              <p><strong>جذب نمایندگی</strong></p>

              <p>
                فروشگاه <strong>پوشاک شاهین کالا</strong> در نظر دارد، جهت توسعه
                کاری و فروش محصولات خود با شما متقاضیان محترم همکاری به عمل آورد
                ، <br/>لذا خواهشمند است جهت دریافت مشاوره و اخذ نمایندگی با پشتیبانی
                تماس حاصل فرمایید.
              </p>

              <ul>
                <li style="text-align: right;">
                    <span>شماره تماس پشتیبانی : </span>
                  <a
                    class="text-dark"
                    :href="`tel:${contact.settings.customer_setting.mobile}`"
                    >{{ contact.settings.customer_setting.mobile }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "branches",
  computed: {
    contact() {
      return this.$store.getters["front/getHomeData"];
    },
  },
};
</script>
<style scoped>
ul li {
  list-style-position: inside;
}
</style>
